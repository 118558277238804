body {
	/*background-color: #1C1C1E;*/
	/*color: #fff;*/
}

#root {

}

.list__items__image {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	max-height: 100%;
	max-width: 100%;
	display: block;
	margin: auto;
	transform: scale(1.2);
	margin:  0px;
}

.main__list {
	margin-top: 5px; /*0 --- 30 - 86 fdn 13-12-21*/
	margin-left:  8px; /* FDN добавлено для симметрии */
}

.MuiAvatar-root {
    width: 110px!important; /*85*/
    height: 110px!important; /*85*/
    margin-right: 10px!important;
	border-top-left-radius: 0%!important;
    border-top-right-radius: 0%!important;
    border-bottom-right-radius: 0%!important;
    border-bottom-left-radius: 0%!important;    
}

.MuiListItem-dense {
	padding-top: 0px!important;
	padding-bottom: 0px!important;
	padding-left: 0px!important;
	padding-right: 0px!important;
	/*color: rgba(255, 255, 255, 0.7)!important;*/
	color: #fff!important;
}

.MuiListItemText-dense>p {
	color: #fff!important;
}

.MuiListItemAvatar-root {
	margin-bottom: 2px;
}

.MuiListItemText-primary {
	/*color: rgba(255, 255, 255, 0.7)!important;	*/
	font-weight: bold!important;
}

.MuiList-root {
	padding-bottom: 0px!important;
	padding-top: 0px!important; /*0*/
}

.item__button_div {
	margin-top: 10px; /*30px - 0*/	
}

.item__button_div>button {
	/*margin-right: 2px!important;*/
	margin: 0px!important;
	margin-top: 0px!important; /*25*/
	margin-left: 7px!important;
	margin-right: 7px!important;
	margin-bottom: 12px!important;
	padding-right:  0px!important;
	padding-left: 0px!important;
	width: 40%;
	background-color: #e1f0fc !important;
}

.item__info_div {
	margin: auto;
	margin-left: 30px;
	padding-right: 30px;
    /*margin-right: 0px;*/
}

.item__first__img {
	/*display: block;*/
    margin-left: auto;
    margin-right: auto;
    background-color: #424242;
    display: flex;
    justify-content: center;
}

.item__last__img {
	/*display: block;*/
	/*margin-top: 15px !important;*/
    margin-left: auto;
    margin-right: auto;
    background-color: #424242;
    margin-top:30px!important;
}

.main--cart {
	padding-left: 3px!important;
	padding-right: 3px!important;
}

.bill--table {
	width: 100%;
	margin-bottom: 10px; /*20*/
	border-collapse: collapse; 
}
/*.bill--table th {
	font-weight: bold;
	padding: 5px;
	background: #a7a2a2;
	border: 1px solid #dddddd;
	color: #000;
}
*/
.bill--table td {
	border: 0px solid #dddddd;
	padding: 0px;
}
.bill--table tr td:first-child, .bill--table tr th:first-child {
	border-left: none;
}
.bill--table tr td:last-child, .bill--table tr th:last-child {
	border-right: none;
}

/*.bill--table thead tr th:first-child {
	width: 12px;
}

.bill--table thead tr th:nth-child(3n) {
	width: 12px;
}
*/
.bill--table tbody tr td:nth-child(3n) {
	text-align: center;
}

/*.bill--table thead tr th:nth-child(4n) {
	width: 12px;
}
*/
.bill--table tbody tr td:nth-child(4n) {
	width: 12px;
	text-align: center;
}


.bill-price-action {
	font-weight: bold;
	margin-top: 0px;
	margin-bottom: 0px;
	display: flex;
	justify-content:space-between;
	align-items: center;
}

.bill-item-delete{
	flex-grow: 1;
    text-align: right;;
}

.bill-price-price {
	font-weight: bold;
	/*margin-top: 10px;*/
}

.bill-price-remove>button {
	padding-left: 0px;
}


.bill-price-action>div>button {
	padding-top: 5px;
	padding-bottom:  5px;
}

.bill-price-remaind {
	padding-left: 10px;
    padding-right: 10px;
}

tr>td>div>div {
	margin-right: 0px!important;
}

.bill--table tr {
	border-bottom: 1px solid #575656;
	border-top: 1px solid #575656;
}

.cart-empty>td {
	padding: 20px;
}

.main-logo {
	margin-top: 20%!important;
	text-align: center;
	margin-bottom: 10px;
	margin-top: 10px;
	transform: scale(1.2);
}

.main-buttons {
	margin-top: 0%; /*25%*/
	text-align: center;
	display:flex;
	flex-direction: column;
}

.main-buttons-root {
	margin-top: 25%;
	text-align: center;
	display:flex;
	flex-direction: column;
}

.main-buttons>a, .main-buttons-root>a{
	margin: 10px;
	/* background-color: #e1f0fc !important; */
}

.MuiButton-root {
		background-color: #e1f0fc !important;
}

.cart-bottom {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.MuiBottomNavigation-root {
	justify-content: space-around!important;
	margin-top: 10px!important;
}

.MuiBottomNavigation-root>button>span>span {
	font-size: large;
}


.form-all-filed {
	display: flex;
    flex-direction: column;
    width: 100%;
}

.form-all-filed>div, .form-all-filed>textarea {
	margin-bottom: 25px;
}

.form-all-filed>label>span {
	font-size: smaller!important;
}

.form-all-filed>button {
	margin-top: 10px;
}

.cart-contact {
	display: flex;
    flex-direction: column;
    width:100%;
    justify-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
}